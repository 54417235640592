<template>
  <div class="withdraw-mb">
    <PageHeader title="Rút tiền" :from-withdraw="true" :show-home-from-page="true" />
    <div class="withdraw-mb__content">
      <div v-for="(item, index) in menuWithdrawMB" :key="index" class="menu-item" :class="{ maintain: item?.isMaintain }" @click="handleTabMenuWithdraw(item)">
        <BaseImg v-if="item.isHot" class="icon-hot" src="/assets/images/icon/hot.svg" alt="tag" />
        <BaseImg
          v-if="item.isMaintain"
          class="icon-maintain"
          src="/assets/images/components/mobile/pages/deposit/tag-menu-maintain.png"
          alt="tag"
        />
        <span class="icon" :class="item.iconMB" />
        <div class="title">{{ item.name }}</div>
        <div class="desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import PageHeader from '~/components/mobile/common/page-header'
import { useToggleMenu } from '~/composables/useToggleMenu'
import { IMenuWithDraw } from '~/types/menu.type'
import { useWithdrawMenu } from '~/composables/account/withdraw/useMenuWithdraw'
const { menuWithdrawMB } = useWithdrawMenu()

const { $device } = useNuxtApp()
const route = useRoute()
const router = useRouter()
const { toggleMenuWithdraw } = useToggleMenu()

const handleTabMenuWithdraw = (item: IMenuWithDraw) => {
  if (item?.isMaintain) {
    return
  }
  if ($device.isMobileOrTablet) {
    toggleMenuWithdraw()
    router.push(item?.link)
  }
}
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/common/page-withdraw/index.scss" />
