<template>
  <div class="search">
    <div class="float-header">
      <div class="search-input">
        <form @submit.prevent="">
          <BaseInput
            v-model="search"
            placeholder="Nhập tên Game"
            required
            close
            special-character
            :limit-input="VALIDATION_INPUT.MAX_LENGTH_SEARCH_GAME"
            autocomplete="off"
            aria-autocomplete="none"
            @update:model-value="onValueUpdate"
          />
        </form>
      </div>
    </div>
    <div class="content">
      <SearchGameList
        v-if="enableSearching"
        :games="resultGames"
        :keyword="currentKeyword"
        :suggested-keywords="suggestedKeywords"
        :is-loading="isLoading"
        :on-press="onPressKeywordSuggestion"
        :total-games="totalGame"
        :page="page"
        @load-more="searchGame($event)"
      />
      <div v-else>
        <div v-show="search.trim().length === 1" class="require-min-length">
          {{ `Nhập từ ${MIN_SEARCH} ký tự để bắt đầu tìm kiếm` }}
        </div>
        <RecentlySearch v-show="recentlySearch.length" :data="recentlySearch" :on-press="onPressKeywordSuggestion" />
        <RecentlyGames v-show="recentlyGames.length" :games="recentlyGames.slice(0,6)" />
        <KeywordSuggestions
          v-show="suggestedKeywords.length"
          :data="suggestedKeywords"
          :on-press="onPressKeywordSuggestion"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import KeywordSuggestions from './keyword-suggestions.vue'
import RecentlyGames from './recently-games.vue'
import RecentlySearch from './recently-search.vue'
import SearchGameList from './game-list.vue'
import BaseInput from '~/components/common/base-input.vue'
import { VALIDATION_INPUT } from '~/constants/common'
import { useSearch } from '~/composables/useSearch'
import { useAppStore } from '~/store/app'

const route = useRoute()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const currentKeyword = ref('')
const MIN_SEARCH = 2
const enableSearching = ref(false)
const {
  isLoading,
  searchGame,
  suggestedKeywords,
  recentlySearch,
  resultGames,
  recentlyGames,
  search,
  totalGame,
  resetSearch,
  page
} = useSearch()

const onValueUpdate = (value: any) => {
  if (value) {
    debouncedSearch()
  } else {
    currentKeyword.value = ''
  }
}

const debouncedSearch = debounce(() => (currentKeyword.value = search.value), 1000)

const onPressKeywordSuggestion = (keyword: any) => {
  currentKeyword.value = keyword
  search.value = keyword
}

const handleSearch = () => {
  resetSearch()
  searchGame()
}

watch(currentKeyword, () => {
  if (search.value.trim().length >= MIN_SEARCH) {
    handleSearch()
    enableSearching.value = true
  } else {
    enableSearching.value = false
    if (search.value.trim().length === 0) {
      handleSearch()
    }
  }
})

watch(isLogged, () => handleSearch())

onMounted(() => {
  const searchQuery = route.query.search
  if (typeof searchQuery === 'string' && searchQuery.length >= 1) {
    const searchValue = searchQuery.replace(/-/g, ' ')
    onPressKeywordSuggestion(searchValue)
  } else {
    handleSearch()
  }
  document.body.style.overflow = 'hidden'
})
onUnmounted(() => {
  document.body.style.overflow = ''
})
</script>

<style scoped src="public/assets/scss/components/mobile/pages/search/index.scss" />
