<template>
  <div class="menu-button">
    <div
      v-for="(item, index) in menu"
      :key="index"
      class="menu-item"
      :class="{
        active: checkActive(item, item.activeUrl)
      }"
      @click="clickMenuItem(item)"
    >
      <div class="action-wrap__icon">
        <BaseImg class="icon" :src="item.icon" :alt="item.name" />
      </div>
      <div class="text">{{ item.name }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { PAGE_URLS } from '~/config/page-url'
import { getBottomMenu } from '~/constants/menu'
import { useToggleMenu } from '~/composables/useToggleMenu'
import { IMenuBottom } from '~/types/menu.type'
import { useGameStore } from '~~/store/game'
import { MODAL_QUERY } from '~/config/constant'

const { $pinia } = useNuxtApp()
const route = useRoute()
const { toggleMenuAccount } = useToggleMenu()
const storeGame = useGameStore($pinia)
const { providerGames } = storeToRefs(storeGame)
const { BOTTOM_MENU } = getBottomMenu()

const emit = defineEmits(['displaySearchPage'])

const menu = computed(() => {
  return BOTTOM_MENU.filter((item) => item.name !== (route.path === PAGE_URLS.HOME ? 'Trang chủ' : 'Khuyến mãi'))
})

const urlGameLobby = `${PAGE_URLS.GAME}/${providerGames?.value[0]?.alias || 'table-games'}`

const clickMenuItem = (item: IMenuBottom) => {
  if (!item?.url) {
    toggleMenuAccount()
    return
  }
  if (item.url === MODAL_QUERY.SEARCH) {
    emit('displaySearchPage', true)
    return
  }
  emit('displaySearchPage', false)
  if (item.key === 'games') {
    navigateTo(urlGameLobby)
  } else {
    navigateTo(item.url)
  }
}
const checkActive = (item: IMenuBottom, activeUrl: string) => {
  if (item.url === BOTTOM_MENU[1].url) {
    return false
  } else {
    if (route.fullPath.includes(MODAL_QUERY.SEARCH) && activeUrl === MODAL_QUERY.SEARCH) {
      return true
    }
    return (route.fullPath.includes(activeUrl) || (route.name === item?.type && route?.params?.type !== 'quay-so')) && !route.fullPath.includes(MODAL_QUERY.SEARCH)
  }
}
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/common/menu-bottom/index.scss" />
