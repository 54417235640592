<template>
  <div class="recently-games">
    <div class="title">Game vừa chơi</div>
    <div class="recently-games__content">
      <Swiper
        ref="banner"
        v-bind="swiperOption"
        class="mySwiper"
      >
        <SwiperSlide
          v-for="page in totalPage"
          :key="page"
          class="swiper-slide"
          :class="{'more-slide': totalPage > 1}"
        >
          <div class="grid-games">
            <CommonGamesItemGame
              v-for="(game, index) in getCurrentList(page)"
              :key="index"
              class="game-item"
              :item="game"
              :show-tag="false"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
import { Navigation, Pagination } from 'swiper'
import CommonGamesItemGame from '~/components/common/game-item.vue'

const props = defineProps({
  games: {
    type: Array,
    default: () => []
  }
})

const numberOfItemPerPage = 6
const totalPage = computed(() => Math.ceil(props.games.length / numberOfItemPerPage))
const getCurrentList = (page: any) => {
  return props.games.slice((page - 1) * numberOfItemPerPage, page * numberOfItemPerPage)
}

const swiperOption = reactive({
  loop: false,
  slidesPerView: 1,
  spaceBetween: 8,
  navigation: true,
  pagination: {
    clickable: true
  },
  modules: [Navigation, Pagination]
})
</script>

<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/search/recently-games.scss" />
