<template>
  <div id="header-mobile" class="header">
    <BaseImg
      id="logo-mobile"
      class="logo"
      relative-src="/assets/brand/logo.webp"
      alt=""
      @click="$router.push(PAGE_URLS.HOME)"
    />
    <div class="header-button">
      <MobileHeaderLoginButton v-if="!currentUser" />
      <MobileHeaderUserLogged v-if="currentUser" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import MobileHeaderLoginButton from '~/components/mobile/header/user-not-login.vue'
import MobileHeaderUserLogged from '~/components/mobile/header/user-logged.vue'
import { PAGE_URLS } from '~~/config/page-url'
import { useHeader } from '~/composables/useHeader'
import { useAppStore } from '@/store/app'
const { currentUser } = useHeader()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { fetchListBank } = useListBank()
const $router = useRouter()
const { isLogged } = storeToRefs(store)
onMounted(async () => {
  await fetchListBank()
})

watch(isLogged, () => {
  fetchListBank()
})

</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/header/index.scss" />
